const partners = [
  {
    id: 1,
    image: '/assets/home/partners/Pinknodes_Logo_C.png',
    link: 'https://pinknode.io/',
    name: 'Pinknode',
  },
  // {
  //   id: 2,
  //   image: '/assets/home/partner-identomat.svg',
  //   link: 'https://identomat.com/',
  //   name: 'Identomat',
  // },
  // {
  //   id: 3,
  //   image: '/assets/home/partner-tapit.svg',
  //   link: 'https://www.tapit.kr/',
  //   name: 'Tapit',
  // },
  {
    id: 4,
    image: '/assets/home/partners/Murall_Logo_C.png',
    link: 'https://murall.art/home',
    name: 'MurAll',
  },
  {
    id: 5,
    image: '/assets/home/partners/EscrowProtocol_Logo_C.png',
    link: 'https://www.escrowprotocol.app/',
    name: 'Escrow',
  },
  // {
  //   id: 6,
  //   image: '/assets/home/partner-wsninja.svg',
  //   link: 'https://www.ws.ninja/',
  //   name: 'WallStreetNinja',
  // },
  {
    id: 7,
    image: '/assets/home/partners/FollowTheSeed_Logo_C.png',
    link: 'https://followtheseed.vc/',
    name: 'Follow the seed',
  },
  {
    id: 8,
    image: '/assets/home/partners/Saito_Logo_C.png',
    link: 'https://saito.io/',
    name: 'Saito',
  },
  {
    id: 9,
    image: '/assets/home/partners/0xMarket_Logo_C.png',
    link: 'https://www.0xmarket.io/',
    name: '0xMarket',
  },
  {
    id: 10,
    image: '/assets/home/partners/AllyDirect_Logo_C.png',
    link: 'https://allynow.com/',
    name: 'Ally',
  },
  {
    id: 11,
    image: '/assets/home/partners/Blockless_Logo_C.png',
    link: 'https://blockless.network/',
    name: 'Blockless',
  },
  {
    id: 12,
    image: '/assets/home/partners/BSCPad_Logo_C.png',
    link: 'https://bscpad.com/',
    name: 'BSCPad',
  },
  {
    id: 13,
    image: '/assets/home/partners/ButterFly_Logo_C.png',
    link: 'https://www.butterflyprotocol.io/',
    name: 'Butterfly Protocol',
  },
  {
    id: 14,
    image: '/assets/home/partners/CessCloud_Logo_C.png',
    link: 'https://cess.cloud/',
    name: 'Cess Cloud',
  },
  {
    id: 15,
    image: '/assets/home/partners/Chainlink_Logo_C.png',
    link: 'https://chain.link/',
    name: 'Chainlink',
  },
  // {
  //   id: 16,
  //   image: '/assets/home/partner-decloud.svg',
  //   link: 'https://launchpad.decloud.org/',
  //   name: 'Decloud',
  // },
  {
    id: 17,
    image: '/assets/home/partners/DotFinance_Logo_C.png',
    link: 'https://dot.finance/',
    name: 'Dot Finance',
  },
  // {
  //   id: 18,
  //   image: '/assets/home/partner-dragd.png',
  //   link: 'https://dra.gd/',
  //   name: 'Dragd',
  // },
  {
    id: 19,
    image: '/assets/home/partners/EQ8_Logo_C.png',
    link: 'https://www.eq8.network/',
    name: 'EQ8 Network',
  },
  {
    id: 20,
    image: '/assets/home/partners/Fear_Logo_C.png',
    link: 'https://www.fear.io/',
    name: 'Fear',
  },
  {
    id: 21,
    image: '/assets/home/partners/Gather_Logo_C.png',
    link: 'https://gather.network/',
    name: 'Gather Network',
  },
  {
    id: 22,
    image: '/assets/home/partners/GhostTrader_Logo_C.png',
    link: 'https://gtr.uk/',
    name: 'Ghost Trader',
  },
  {
    id: 23,
    image: '/assets/home/partners/Lendfi_Logo_C.png',
    link: 'https://lendefi.finance/',
    name: 'Lendefi Finance',
  },
  {
    id: 24,
    image: '/assets/home/partners/Lighthouse_Logo_C.png',
    link: 'https://www.lighthouse.storage/',
    name: 'Lighthouse Storage',
  },
  {
    id: 25,
    image: '/assets/home/partners/Lync_Logo_C.png',
    link: 'https://www.lync.world/',
    name: 'Lync World',
  },
  {
    id: 26,
    image: '/assets/home/partners/MyraAI_Logo_C.png',
    link: 'https://www.myra-ai.tech/',
    name: 'Myra AI',
  },
  {
    id: 27,
    image: '/assets/home/partners/Newscrypto_Logo_C.png',
    link: 'https://newscrypto.io/',
    name: 'Newscrypto',
  },
  {
    id: 28,
    image: '/assets/home/partners/NFTing_Logo_C.png',
    link: 'https://www.nfting.store/',
    name: 'NFTing',
  },
  {
    id: 29,
    image: '/assets/home/partners/O3Swap_Logo_C.png',
    link: 'https://o3swap.com/',
    name: 'O3 Swap',
  },
  {
    id: 30,
    image: '/assets/home/partners/PaidNetwork_Logo_C.png',
    link: 'https://paidnetwork.com/',
    name: 'Paid Network',
  },
  {
    id: 31,
    image: '/assets/home/partners/PantherProtocol_Logo_C.png',
    link: 'https://www.pantherprotocol.io/',
    name: 'Panther Protocol',
  },
  {
    id: 32,
    image: '/assets/home/partners/PreSearch_Logo_C.png',
    link: 'https://presearch.io/',
    name: 'Presearch',
  },
  // {
  //   id: 33,
  //   image: '/assets/home/partner-rariko.svg',
  //   link: 'https://www.rariko.io/',
  //   name: 'Rariko',
  // },
  {
    id: 34,
    image: '/assets/home/partners/RazeNetwork_Logo_C.png',
    link: 'https://www.raze.network/',
    name: 'Raze Network',
  },
  {
    id: 35,
    image: '/assets/home/partners/Reef_Logo_C.png',
    link: 'https://reef.io/',
    name: 'Reef',
  },
  {
    id: 36,
    image: '/assets/home/partners/UniFarm_Logo_C.png',
    link: 'https://unifarm.co/',
    name: 'UniFarm',
  },
  // {
  //   id: 37,
  //   image: '/assets/home/partner-0xCarbon.svg',
  //   link: 'https://0xcarbon.org/',
  //   name: '0xCarbon',
  // },
  {
    id: 38,
    image: '/assets/home/partners/YellowRoad_Logo_C.png',
    link: 'https://yellow-road.web.app/',
    name: 'Yellow Road',
  },
  {
    id: 39,
    image: '/assets/home/partners/Chernihiv_Logo_C.png',
    link: 'https://kpi.stu.cn.ua/en/ukraines-national-university-uses-stackos-to-assist-ukrainian-refugees-2/',
    name: 'Chernihiv Polytechnic National University, Ukraine',
  },
  // {
  //   id: 40,
  //   image: '/assets/home/partners/Blindex_Logo_C.png',
  //   link: '',
  //   name: 'Blindex',
  // },
  {
    id: 41,
    image: '/assets/home/partners/Fantasma_Logo_C.png',
    link: '',
    name: 'Fantasma',
  },
  {
    id: 42,
    image: '/assets/home/partners/PledgedCapital_Logo_C.png',
    link: 'https://pledged-capital.app/',
    name: 'Pledged Capital',
  },
  {
    id: 43,
    image: '/assets/home/partners/PolyNetwork_Logo_C.png',
    link: 'https://poly.network/',
    name: 'PolyNetwork',
  },
  {
    id: 44,
    image: '/assets/home/partners/Propel_Logo_C.png',
    link: '',
    name: 'Propel',
  },
  {
    id: 45,
    image: '/assets/home/partners/QAO_Logo_C.png',
    link: '',
    name: 'QAO',
  },
  {
    id: 46,
    image: '/assets/home/partners/SkyrimFinance_Logo_C.png',
    link: '',
    name: 'Skyrim Finance',
  },
  {
    id: 47,
    image: '/assets/home/partners/Spagett_Logo_C.png',
    link: '',
    name: 'Spagett',
  },
];

const mainPartners = [
  {
    id: 1,
    name: 'BSC',
    image: '/assets/home/partners/BSC_Logo_C.png',
    link: 'https://www.bnbchain.org/',
  },
  {
    id: 2,
    name: 'Polygon',
    image: '/assets/home/partners/Polygon_Logo_C.png',
    link: 'https://polygon.technology/',
  },
  { id: 3, name: 'Neo', image: '/assets/home/partners/Neo_Logo_C.png', link: 'https://neo.org/' },
  {
    id: 4,
    name: 'Avalanche',
    image: '/assets/home/partners/Avalanche_Logo_C.png',
    link: 'https://www.avax.network/',
  },
];

const contributors = [
  {
    id: 1,
    name: 'Vishnu Korde',
    title: 'CEO & Chief Architect',
    image: '/assets/home/contributors/Vishnu Korde.png',
  },
  {
    id: 2,
    name: 'Iman Van Der Maas',
    title: ' Chief Marketing Officer',
    image: '/assets/home/contributors/Iman_V_Maas_R_2 (1).png',
  },
  {
    id: 3,
    name: 'Bhargav Vishal',
    title: 'Head of Strategic Partnerships',
    image: '/assets/home/contributors/Bhargav Vishal.png',
  },
  {
    id: 5,
    name: 'Parry Sondhi',
    title: 'Head of Strategic Execution',
    image: '/assets/home/contributors/Parry Sondhi.jpg',
  },
  {
    id: 6,
    name: 'Nikita Parikh',
    title: 'Director, Product Management',
    image: '/assets/home/contributors/Nikita Parikh.png',
  },
  {
    id: 7,
    name: 'Arjun Korde',
    title: 'Partnership Strategist',
    image: '/assets/home/contributors/Arjun Korde.png',
  },

  {
    id: 8,
    name: 'Abdul Samad',
    title: 'Community Strategist',
    image: '/assets/home/contributors/Abdul Samad.png',
  },
  {
    id: 9,
    name: 'Solomon Solotiger',
    title: 'Data Analyst',
    image: '/assets/home/contributors/Solomon Solotiger.png',
  },
  {
    id: 10,
    name: 'Andrey Shirben',
    title: 'Strategic Advisor & Investor',
    image: '/assets/home/contributors/Andrey Shirben.png',
  },

  {
    id: 11,
    name: 'Fabio Canesin',
    title: 'Strategic Advisor & Investor',
    image: '/assets/home/contributors/Fabio Canesin.webp',
  },
  {
    id: 12,
    name: '0xCarbon',
    title: 'Development Partner',
    image: '/assets/home/contributors/0xcarbon.jpg',
  },
  {
    id: 13,
    name: 'DeCloud Foundation',
    title: 'Development Partner',
    image: '/assets/home/contributors/decloud.jpg',
  },
];

export { partners, mainPartners, contributors };
